<template>
  <div>
    <b-card>
      <div class="row mb-4">
        <div class="col-12">
          <modal-actions
            @close="$router.push({ name: 'technical-capacity' })"
          />
        </div>
      </div>
      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p class="h2 text-secondary">Modifier capacité technique</p>
          <b-button
            v-if="editMode"
            variant="secondary"
            @click="editMode = !editMode"
            >Confirmer</b-button
          >
          <hr class="w-100" />
        </div>

        <div class="row">
          <!-- First Col -->
          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="12"
              label="Libellé"
              label-class="font-weight-bold"
            >
              <b-form-input type="text"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Capacité de rattachement"
              label-class="font-weight-bold"
            >
              <b-form-input type="text"></b-form-input>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="12"
              label="Niveau"
              label-class="font-weight-bold"
            >
              <b-form-input type="number"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Composants technique"
              label-class="font-weight-bold"
            >
              <multiselect
                multiple
                label="name"
                track-by="name"
                :options="[
                  { name: 'Composant technique 1', value: 1 },
                  { name: 'Composant technique 2', value: 2 },
                  { name: 'Composant technique 3', value: 3 },
                ]"
                placeholder="Choisir les composants techniques"
              ></multiselect>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";

export default {
  components: {
    ModalActions,
  },
  data: () => ({
    editMode: false,
  }),
  methods: {},
};
</script>

<style></style>
